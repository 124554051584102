<template>
  <div class="company-div-proj">
    <div class="sub-section">
       <project v-for="project in projects"
          :key="project._id"
          :project="project"
          :editable="true"
        />

        <project v-if="adding"
        :project="adding"
        :editable="true"
         @editClosed="editClosed"
        />

        <div class="cont-btn">
          <div class="btn-green radius full" @click="addNew">
            Add New Project
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Project from '@/components/EmployeeComponents/CompanySettings/_project.vue';

export default {
  name: 'companyDivProj',
  components: {
    Project,
  },
  data() {
    return {
      adding: false,
    };
  },
  computed: {
    projects() {
      return this.$store.getters['company/projects'];
    },
  },
  methods: {
    addNew() {
      this.adding = {
        name: null,
        startDate: null,
        endDate: null,
      };
    },
    editClosed() {
      this.adding = false;
    },
  },

};
</script>

<style lang="scss">
  @import "@/assets/scss/_variables.scss";
  .company-div-proj{
    width: 100%;
    .cont-btn{
      .btn-green{
        text-align: center;
      }
    }
  }
</style>
