<template>
  <company-attr v-model="value" :label="'Company Parity'" :type="'custom'"
    @submitted="update()"
  >
    <template v-slot:display>
      <div class="val">
        <span class="parity-img">
          <img :src="require('@/assets/pictos/picto_sexmale_darkpurple.svg')"/>
        </span>
        {{value * 100}}%
        <span class="parity-img">
          <img :src="require('@/assets/pictos/picto_sexfemale_darkpurple.svg')"/>
        </span>
        {{(1 - value).toFixed(4) * 100}}%
      </div>
    </template>
    <template v-slot:edit>
      <div class="val contSlider parity">
        <div class="marker-sex female">
          <img :src="require('@/assets/pictos/picto_sexmale_darkpurple.svg')">
        </div>
        <div class="marker-sex male">
          <img :src="require('@/assets/pictos/picto_sexfemale_darkpurple.svg')">
        </div>
        <VueSlider
          v-model="editVal"
          :min="0"
          :max="1"
          :interval="0.05"
          :enable-cross="false"
          :tooltip-formatter="stringParity"
        />
      </div>
    </template>

  </company-attr>
</template>

<script>
import VueSlider from 'vue-slider-component';
import CompanyAttr from '@/components/EmployeeComponents/CompanySettings/companyAttr.vue';
import 'vue-slider-component/theme/default.css';

export default {
  name: 'CompanyParity',
  components: {
    CompanyAttr,
    VueSlider,
  },
  data() {
    return {
      editVal: this.value,
      stringParity: (v) => `${Math.round(v * 100)}% / ${Math.round(100 - (v * 100))}%`,
    };
  },
  props: {
    value: {
      type: Number,
      default: 0.5,
    },
  },
  computed: {

  },
  methods: {
    update() {
      this.$emit('input', this.editVal);
    },
    resetEdit() {
      this.editVal = this.value;
    },
  },

};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
.parity-img{
  margin-left:1vw;
  width: 2vh;
  height: 1vh;
  display: inline-block;
  img{
    width: 100%;
    transform: translateY(.5vh);
  }
  &:first-child{
    margin-left: 0;
  }
}

.contSlider {
  position: relative;
  padding-top: 4vh;
  width: 80%;
  .marker-sex{
    position: absolute;
    top: 1vh;
    width: 3vh;
    &.female{
      left: 0;
      transform: translateX(-30%);
    }
    &.male{
      right: 0;
      transform: translateX(40%);
    }
  }
  &.parity{
    width: 96%;
    .vue-slider{
      .vue-slider-rail{
        // border: 0.2vh solid $logo_color_dark;
        background-color: $red_color;
      }
      .vue-slider-process{
        background-color: $blue_color;
      }
      .vue-slider-dot-handle{
        background-color: $logo_color_dark;
        box-shadow: $box_shadow_middle;
      }
      .vue-slider-dot-tooltip-inner{
        background-color: $logo_color;
        .vue-slider-dot-tooltip-text{
          font-size: $classic_min_fs;
          text-decoration: none;
        }
        &::after{
          border-top-color: $logo_color;
        }
      }
    }
  }
}

</style>
