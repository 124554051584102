<template>
  <div class="company-resume">
    <div class="info radius">
      <div class="info-title">
        <div class="picto">
          <img :src="company.companyPicture">
        </div>
        <div class="name-tag">
          <div class="name">
            <p>
              {{company.name}}
            </p>
          </div>
        </div>
      </div>
      <div class="info-attr">
        <div class="elem">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="txt">
            {{company.activitySector || 'Technology'}}
          </div>
        </div>
        <div class="elem">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="txt">
            {{company.localisation}}
          </div>
        </div>
        <div class="elem">
          <div class="picto">
            <img :src="require('@/assets/pictos/picto_localisation_darkpurple.svg')">
          </div>
          <div class="txt">
            <a :href="company.webSite">{{company.webSite}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="bio radius" id="bio-company" :class="{expanded: bioExpand }">
      <div class="bio-header">
        About {{company.name}} ?
      </div>
      <div v-html="company.description" />
      <div class="expand-button" :class="bioExpand ? 'open': 'closed'" @click="toggleExpand"
        v-if="overflow"
      >
        <img :src="require('@/assets/pictos/picto_arrow_round.svg')" />
      </div>
    </div>
    <tech-slider :techList="company.technologies" :label="'Technologies'"/>
    <div class="company-stats radius">
      <div class="stat-title">
       Key Figures
      </div>
      <div class="stat-cont">
        <div class="stat-entry box-shadow">
          <div class="stat-label">
            Established
          </div>
          <div class="stat-value">
            {{company.yearFounded}}
          </div>
        </div>
        <div class="stat-entry box-shadow">
          <div class="stat-label">
            Number of Employees
          </div>
          <div class="stat-value">
            {{company.numEmployees}}
          </div>
        </div>
        <div class="stat-entry box-shadow">
          <div class="stat-label">
            Parity
          </div>
          <div class="stat-value" v-if="company.parity">
            {{`${company.parity*100}% - ${(1 - company.parity) *100}%`}}
          </div>
          <div class="stat-value" v-else>
            50% - 50%
          </div>
        </div>
        <div class="stat-entry box-shadow">
          <div class="stat-label">
            Average Age
          </div>
          <div class="stat-value">
            {{company.averageAge}}
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import TechSlider from '@/components/EmployeeComponents/CompanySettings/technologySlider.vue';

export default {
  name: 'CompanyResume',
  components: {
    TechSlider,
  },
  computed: {
    company() {
      return this.$store.getters['company/info'];
    },

  },
  data() {
    return {
      bioExpand: false,
      overflow: true,
    };
  },
  methods: {
    toggleExpand() {
      this.bioExpand = !this.bioExpand;
    },
    checkOverflow() {
      const element = document.getElementById('bio-company');
      if (element) {
        return element.scrollHeight > element.clientHeight;
      }
      return true;
    },
  },
  mounted() {
    this.overflow = this.checkOverflow();
  },
  watch: {
    'company.description': function () {
      this.overflow = this.checkOverflow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.company-resume{
  width: 100%;
  position: relative;
  .info{
   border: 0.1vh #8c28e1 solid;
   width: calc(100% - 2.2vh);
   position: relative;
   padding: 1vh;
    .info-title{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      .name-tag{
        display:flex;
        flex-direction: column;
        width: calc(100% - 7.5vh);
        .name{
          font-size: 2.4vh;
          margin-bottom: 0.5vh;
          color:#8c28e1;
          font-weight: 600;
          p{
          }
        }
        .slogan{
          font-size: 1.25vh;
          font-weight: 600;
        }
      }
      .picto{
        position: relative;
        width: 6vh;
        height: 6vh;
        overflow: hidden;
        border-radius: 50%;
        background-color: #151515;
        color: white;
        font-size: 2vh;
        line-height: 2vh;
        border: 1px solid white;
        margin-right: 1vh;

        img{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 100%;
        }
      }
    }
    .info-attr{
      position: relative;
      display: flex;
      flex-direction: column;
      margin-top: 1vh;
      justify-content: space-between;
      .elem{
        position: relative;
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1vh;
        margin-bottom: 1.5vh;

        .picto{
          position: relative;
          width: 1.5vw;
          font-size: 0;
          margin-right: 0.5vh;
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            width: 150%;
          }
            .small{
              width: 100% !important ;
            }
        }
        .txt{
          font-size: 1.5vh;
          line-height: 1.5vh;
          text-align: center;
        }
      }
    }
  }
  .bio{
    padding: 1vh 2vh 2vh 1vh;
    height: 10vh;
    width: calc(100% - 3vh);
    background-color: #160033;
    overflow: hidden;
    font-size: 1.5vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
    // border: 0.1vh #8c28e1 solid;
    color: #FFF;
    position: relative;
    &.expanded{
      height: fit-content !important;
    }
    .bio-header{
      color: #48ffaf;
      margin-bottom: 1vh;
      font-size: 2vh;
    }
    .expand-button{
      position: absolute;
      bottom:1vh;
      right: 1vh;
      cursor: pointer;
      transition: all .2s;
      &:hover{
        opacity: 0.8;
      }
      &.open{
        transform: rotate(180deg);
      }
    }
  }
  .company-stats{
    background-color: $new_bg_grey;
    width: 100%;
    margin-top: 2vh;
    .stat-title{
      color: $logo_color;
      font-weight: 600;
      // margin-bottom: 0.5vh;
      padding: 1vh 1vh 0 1vh;
    }
    .stat-cont{
      padding: 1vh;
      // background-color: #f8f8f8;
      border-radius: 10px;
    .stat-entry{
      width: calc(100% - 2vh);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 2vh;
      line-height: 2vh;
      background-color: #fff;
      padding: 1vh;
      border-radius: 10px;
      margin-bottom: 1vh;
      .stat-label{
        font-size: 1.5vh;
      }
      .stat-value{
        font-size: 1.8vh;
        font-weight: 600;
        color: $logo_color;
      }
    }
    }
  }
}
</style>
