<template>
  <div class="component-fullwidth" style="height: 100%;">
    <div class="flex-container flex-full no-padding no-margin">
      <div class="elem-flex">
        <div class="title-page">
          Settings
        </div>
      </div>
    </div>

    <div class="flex-container main-cont">
        <div  class="company-preview">
          <div class="select-group">
            <div class="option" @click="() => {this.previewSelect = 'resume'}"
              :class="{selection: this.previewSelect === 'resume'}"
              >
              Overview
              <div class="underline" v-if=" this.previewSelect === 'resume'" />
            </div>
            <div class="option" @click="() => {this.previewSelect = 'projects'}"
              :class="{selection: this.previewSelect === 'projects'}"
              >
              Projects
              <div class="underline" v-if=" this.previewSelect === 'projects'" />
            </div>
          </div>
          <div class="content-cont" id="preview-cont">
            <company-projects v-if="this.previewSelect === 'projects'"/>
            <company-resume v-if="this.previewSelect === 'resume'"/>
          </div>
        </div>
        <div class="gutter" />
        <div class="settings">
          <div class="select-group">
            <div v-for="section in optionSections"
              :key="section"
              class="option"
              @click="() => {currentSection = section; goTop();}"
              :class="{selection: currentSection === section}"
              >
              {{section}}
              <div class="underline" v-if="section === currentSection" />
            </div>
          </div>
          <div class="content-cont" id="settings-cont" >
            <!-- General Settings -->
            <template v-if="currentSection === 'General'">
              <company-attr v-model="company.name" :label="'Company Name'" />
              <company-attr v-model="company.localisation" :label="'Localization'" :darker="true" />
              <company-attr v-model="company.activitySector" :label="'Activity Sector'"  />
              <company-attr v-model="company.webSite" :label="'Website'" :darker="true" />
            </template>

            <!-- Description Settings -->
            <template v-if="currentSection === 'Description'">
              <company-attr v-model="company.description" :label="'Description'" :type="'html'" />
              <company-attr v-model="company.companyPicture" :label="'Company Picture'"
                :type="'proPic'" :darker="true"
              />
              <company-attr v-model="company.companyBackgroundPicture" :label="'Wallpaper'"
                :type="'media'"
              />
            </template>

            <template v-if="currentSection === 'Technologies'">
              <technology-list :technologyList="this.technologies" :update="updateTech" />
            </template>

            <template v-if="currentSection === 'Other'">
              <company-attr v-model="company.yearFounded" :label="'Established'"
                :type="'number'"
              />
              <company-attr v-model="company.numEmployees" :label="'Employees'"
                :type="'number'"
                :darker="true"
              />
              <company-parity v-model="company.parity"/>
              <company-attr
                v-model="company.averageAge" :label="'Average Age'"
                :darker="true" :type="'number'"
              />

            </template>

            <template v-if="currentSection === 'Social'">
              <company-social v-for="(social, index) in socials" :key="index"
                v-model="socials[index]"
                :remove="() => deleteEntry(index, 'socials')"
                :startEdit="!social.site && !social.link"
                @submitted="updateSocials"
              >
              </company-social>
              <add-button-big :add="this.addSite" :label="'Add new site'"/>

            </template>

            <template v-if="currentSection === 'API Integrations'">
              <company-attr v-model="company.hackerRankToken" :label="'Hacker Rank'" />

            </template>

            <!-- <div class="cont-next-section">
              <div class="next-section radius-small" @click="nextSection">
                Next parameter
              </div>
            </div> -->
          </div>
        </div>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex';
import CompanyProjects from '@/components/EmployeeComponents/CompanySettings/companyDivProj.vue';
import PerfectScroll from '@/modules/mixins/perfectScrollInit';
import CompanyResume from '@/components/EmployeeComponents/CompanySettings/companyResume.vue';
import CompanyAttr from '@/components/UniversalComponents/Settings/settingAttr.vue';
import CompanySocial from '@/components/EmployeeComponents/CompanySettings/companySocial.vue';
import AddButtonBig from '@/components/UniversalComponents/Settings/_addButtonBig.vue';
import TechnologyList from '@/components/UniversalComponents/Settings/_technologies.vue';
import CompanyParity from '@/components/EmployeeComponents/CompanySettings/companyParity.vue';
import '@/assets/scss/settings/selector.scss';

export default {
  name: 'EditCompanyProfile',
  components: {
    CompanyProjects,
    CompanyResume,
    CompanyAttr,
    CompanySocial,
    AddButtonBig,
    TechnologyList,
    CompanyParity,
  },
  data() {
    return {
      adding: false,
      optionSections: ['General', 'Description', 'Technologies', 'Other', 'Social', 'API Integrations'],
      company: {},
      socials: [],
      technologies: [],
      edit: {
        image: false,
        imageBackground: false,
      },
      previewSelect: 'resume',
      currentSection: 'General',
      loadingGlobal: false,
      scrollset: false,
      sending: false,
      stringParity: (v) => `${Math.round(v * 100)}% / ${Math.round(100 - (v * 100))}%`,
    };
  },
  computed: {
    ...mapState('company', {
      companyInfo: (state) => state.companyInfo,
    }),
    projects() {
      return this.$store.getters['company/projects'];
    },
    maxSocials() {
      return this.socials.length >= 6;
    },
    empty() {
      return Object.keys(this.company).length === 0;
    },

  },
  methods: {
    getInfo() {
      const companyInfo = { ...this.$store.getters['company/info'] };
      this.company = {
        _id: companyInfo._id,
        name: companyInfo.name,
        localisation: companyInfo.localisation,
        webSite: companyInfo.webSite,
        yearFounded: companyInfo.yearFounded,
        numEmployees: companyInfo.numEmployees,
        parity: companyInfo.parity,
        averageAge: companyInfo.averageAge,
        description: companyInfo.description || '',
        activitySector: companyInfo.activitySector,

        companyPicture: companyInfo.companyPicture,
        companyBackgroundPicture: companyInfo.companyBackgroundPicture,
        hackerRankToken: companyInfo.hackerRankToken,
      };
      this.socials = [...companyInfo.socials];
      this.technologies = this.$store.getters['company/info'].technologies;
    },
    limitString(stringVal, limit) {
      let newString = stringVal;
      if (newString.length > limit) {
        newString = `${newString.substring(0, (limit - 3))}...`;
      }
      return newString;
    },

    onInputDesc(e) {
      this.company.description = e.target.innerText;
    },
    submit(editBody) {
      this.sending = true;

      this.$store.dispatch('company/updateInfo', editBody).then(() => {
        this.sending = false;
      });
    },
    addSite() {
      if (!this.maxSocials) {
        this.socials.push({ site: '', link: '' });
      }
    },
    deleteEntry(index) {
      this.socials.splice(index, 1);
      this.updateSocials();
    },
    goPreview() {
      this.$router.push(`/company/${this.company._id}`);
    },
    newTabBrowser() {
      window.open(`${window.location.origin}/company/${this.company._id}`, '_blank');
    },

    updateSocials() {
      this.$store.dispatch('company/updateInfo', { socials: this.socials }).then((newComp) => {
        this.socials = newComp.socials;
      });
    },
    updateTech(newTech) {
      return new Promise((resolve) => {
        this.$store.dispatch('company/updateInfo', { technologies: newTech }).then((newComp) => {
          this.technologies = newComp.technologies;
          resolve(newComp.technologies);
        });
      });
    },

    goTop() {
      const element = document.getElementById('settings-cont');
      this.$nextTick(() => {
        element.scrollTop = 0;
      });
    },
    nextSection() {
      const currentIndex = this.optionSections.indexOf(this.currentSection);
      if (currentIndex < this.optionSections.length - 1) {
        this.currentSection = this.optionSections[currentIndex + 1];
      }
    },
  },
  mounted() {
    if (Object.keys(this.$store.getters['company/info']).length > 0) {
      this.getInfo();
    }
    this.$store.dispatch('company/getInfo').then(() => {
      this.getInfo();
    });
    PerfectScroll('#preview-cont', false, { initOptions: { suppressScrollX: true } });
    PerfectScroll('#settings-cont', false, { initOptions: { suppressScrollX: true } });
  },
  watch: {
    company: {
      deep: true,
      handler(newVal) {
        const companyInfo = this.$store.getters['company/info'];

        this.sending = true;
        const editBody = {};
        Object.keys(newVal).forEach((key) => {
          if (newVal[key] !== companyInfo[key]) {
            editBody[key] = newVal[key];
          }
        });

        if (Object.keys(editBody).length > 0) {
          this.submit(editBody);
        }
      },
    },
    socials: {
      deep: true,
      handler() {
      },
    },
  },

};
</script>

<style lang="scss" scoped>
.main-cont{
  height: 100%;
  justify-content: space-between;
  .company-preview{
    width: 25%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .select-group{
      padding-left: 0;
    }
    .content-cont{
      padding-left: 0;
      padding-right: 1vh;
    }
  }
  .gutter{
    width: 5%;
  }
  .settings{
    width: 65%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .select-group{
      padding-left: 0;
    }
    .content-cont{
      padding-left: 0;
    }
  }
  .content-cont{
    width: 100%;
    position: relative;
    flex-direction: column;
    padding: 0.5vh;
    margin-top: 1vh;
    height: 72.5vh;
    justify-content: space-between;
    overflow: hidden;

    .cont-next-section{
      width: calc(100% - calc(5vw - 2vh));
      display: flex;
      justify-content: flex-end;
      .next-section{
        position: relative;
        display: block;
        text-align: center;
        background-color: #8c28e1;
        color: #FFF;
        transition: background-color 0.3s;
        font-size: 1.4vh;
        padding: 1vh 1.5vh;
        cursor: pointer;
      }
    }

  }

  .select-group{
    display: flex;
    width: 100%;
    padding: 1vh;
    flex-direction: row;
    justify-content: start;
    margin-bottom: 1vh;
    .option{
      font-size: 1.8vh;
      font-weight: 600;
      margin-right: 1vw;
      cursor: pointer;
      margin-right: 3vw;
      position: relative;
      .underline{
        position: absolute;
        width: 50%;
        background-color:#8c28e1 ;
        border-radius: 0.25vh;
        margin-top: 1vh;
        height: 4px;
        border-radius: 2px;
        transition: all 0.2s;

      }
      &.selection{
        font-family: "MontserratBold";
      }
    }

}

}

</style>
